import { API } from 'aws-amplify'
import { format } from 'date-fns'
import React, { useContext } from 'react'
import { IWorker, IAssignmentKey } from '../interfaces'
import { IAssignmentsForUpdate, IMappedAssignments } from '../interfaces/assignments'
import { IConfigProps } from '../interfaces'

const getTwilioToken = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'TwilioActions' },
    }
    return await API.get(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}

const getAssignmentsCount = async (key?: string, value?: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'Assignments',
        action: 'scan',
      },
      body: {
        config: {
          method: `getAssignmentsCount`,
          ...(value &&
            key && {
              [`${key}`]: value,
            }),
        },
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}
const getAssignments = async () => {
  try {
    const params = {
      queryStringParameters: {
        table: 'Assignments',
        action: 'scan',
      },
      body: {
        config: {
          page: 0,
          pageSize: 5000,
          method: `getAllAssignments`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAssignmentsCount()
    return await getAllPagesFunction({ params, count })
  } catch (e) {
    console.error(e)
  }
}

export const awesomeCoolRecursiveScanFunction = async ({ params }: any) => {
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  return await API.post(apiName, apiPath, params).then(async (response: any) => {
    if (response.LastEvaluatedKey) {
      const newParams = JSON.parse(JSON.stringify(params))
      newParams.body.LastEvaluatedKey = response.LastEvaluatedKey
      const pageResult = await awesomeCoolRecursiveScanFunction({
        params: newParams,
      })
      response.Items.push(...pageResult)
      return response.Items
    } else {
      return response.Items
    }
  })
}

export const awesomeCoolRecursivePageFunction = async ({ params }: any) => {
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  return await API.post(apiName, apiPath, params).then(async (result: any) => {
    if (result.response.length === params.body.pageSize) {
      const newParams = JSON.parse(JSON.stringify(params))
      ++newParams.body.page
      const pageResult = await awesomeCoolRecursivePageFunction({
        params: newParams,
      })
      result.response.push(...pageResult)
      return result.response
    } else {
      return result.response
    }
  })
}

export const getAllPagesFunction = async ({ params, count = 1 }: any) => {
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  const { pageSize } = params.body.config
  const pageCount = Math.ceil(count / pageSize)
  const promise = []
  for (let i = 0; i < pageCount; i++) {
    params.body.config.page = i
    promise.push(API.post(apiName, apiPath, params))
  }
  return await Promise.allSettled(promise)
    .then((result: any) => {
      const results = []
      if (result) {
        for (const {
          value: { response },
          status,
        } of result) {
          if (status === 'fulfilled') results.push(...response)
        }
      }
      return results
    })
    .catch((e) => console.error('Error in getAllPagesFunction', e))
}
const getAssignmentsForTraining = async (trainingId: any) => {
  try {
    const params = {
      queryStringParameters: {
        table: 'Assignments',
        action: 'scan',
      },
      body: {
        config: {
          trainingId,
          page: 0,
          pageSize: 5000,
          method: `getAllAssignmentsForTraining`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAssignmentsCount(`trainingId`, trainingId)
    return await getAllPagesFunction({ params, count })
  } catch (e) {
    console.error(e)
  }
}

const getAssignmentsByBundleId = async (trainingBundleId: string) => {
  try {
    const params = {
      queryStringParameters: {
        table: 'Assignments',
        action: 'scan',
      },
      body: {
        config: {
          trainingBundleId,
          page: 0,
          pageSize: 5000,
          method: `getAllAssignmentsForBundle`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAssignmentsCount(`trainingBundleId`, trainingBundleId)
    return (await getAllPagesFunction({ params, count })) || []
  } catch (e) {
    console.error(e)
  }
}
const getUpdatedAssignmentsByBundleId = async (trainingBundleId: string) => {
  try {
    const params = {
      queryStringParameters: {
        table: 'Assignments',
        action: 'scan',
      },
      body: {
        config: {
          bundleId: trainingBundleId,
          page: 0,
          pageSize: 5000,
          method: `getAllAssignmentsForBundleForTheLastMinutes`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAssignmentsCount(`bundleId`, trainingBundleId)
    return (await getAllPagesFunction({ params, count })) || []
  } catch (e) {
    console.error(e)
  }
}

const getAssignmentsByWorker = async (workers: IWorker[]) => {
  try {
    const empIds: string[] = workers.map((worker: IWorker) => worker.empId)
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'Assignments', action: 'scan' },
      body: {
        empId: empIds,
      },
    }
    return API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}

const getAssignmentsByAssignmentId = async (assignmentIds: string[]) => {
  try {
    const params = {
      queryStringParameters: { table: 'Assignments', action: 'scan' },
      body: {
        paginate: true,
        scan: {
          assignmentId: assignmentIds,
        },
      },
    }
    const response = await awesomeCoolRecursiveScanFunction({ params })
    return response
  } catch (e) {
    console.error('getAssignmentsByAssignmentId ERROR: ', e)
  }
}

const mapAssignmentIdsForBatchGet = (ids: string[]) => {
  if (!ids.length) return []
  return ids.reduce((acc: IAssignmentKey[], assignmentId) => {
    const [trainingId] = assignmentId.split(':')
    return [...acc, { assignmentId, trainingId }]
  }, [])
}
const batchGetAssignmentsByAssignmentId = async (ids: string[]) => {
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  const chunkedAssignmentIds = chunkArray(ids, 100)
  const assignments: any[] = []
  for (const assignmentIds of chunkedAssignmentIds) {
    try {
      const params = {
        queryStringParameters: {
          table: 'Assignments',
          action: 'scan',
        },
        body: {
          config: {
            assignmentIds,
            page: 0,
            pageSize: 5000,
            method: `getAllAssignmentsForAssignmentIds`,
          },
        },
      }
      const { response = [] } = await await API.post(apiName, apiPath, params)
      if (response.length) {
        assignments.push(...response)
      }
    } catch (e) {
      console.error('getAssignmentsByAssignmentId ERROR: ', e)
    }
  }
  return assignments
}

const chunkArray = (array: any[], chunkSize = 500) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill('')
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))

const postAssignmentsData = async (table: string, options: any) => {
  const {
    reassign,
    employeeIds,
    trainingId,
    trainingIds = [],
    bundleId = '',
    startDate,
    dueDays,
    endDays,
    assignmentType = '',
  } = options
  const promises = []
  const chunkedEmpIds: string[][] = chunkArray(employeeIds, 200)
  try {
    for (const chunk of chunkedEmpIds) {
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      const params: IConfigProps = reassign
        ? {
            queryStringParameters: { table, action: 'reassign' },
            body: {},
          }
        : { queryStringParameters: { table }, body: {} }

      if (trainingId) {
        params.body.trainingId = trainingId
      }
      if (bundleId) {
        params.body.bundleId = bundleId
        params.body.trainingIds = trainingIds
      }
      params.body.assignmentType = assignmentType
      params.body.employeeIds = chunk
      params.body.startDate = new Date(startDate).toISOString()
      params.body.dueDays = dueDays
      params.body.endDays = endDays
      promises.push(API.post(apiName, apiPath, params))
    }
    await Promise.allSettled(promises)
  } catch (ex) {
    console.error(ex)
  }
}

const updateAssignments = async (
  link: string,
  list: IAssignmentsForUpdate[],
  action = 'reassign',
) => {
  try {
    const promises = list.map((assignment) => {
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      const bodyBuilder = {
        trainingId: assignment.trainingId,
        employeeIds: Array.isArray(assignment.empId) ? assignment.empId : [assignment.empId],
        startDate: new Date(assignment.startDate).toISOString(),
        dueDays: assignment.dueDays,
        endDays: assignment.endDays,
        ...(assignment?.trainingBundleId && {
          bundleId: assignment.trainingBundleId,
        }),
      }
      let params = {
        queryStringParameters: {
          table: 'Assignments',
          action,
        },
        body: bodyBuilder,
      }
      return API.post(apiName, apiPath, params)
    })
    return await Promise.all(promises)
  } catch (ex: any) {
    console.error(ex)
    if (ex.message.includes('404')) {
      throw new Error('Training could not be assigned. The training ID could not be found')
    }
  }
}

const formatDateOnly = (date: any) => format(new Date(date), 'MM/dd/yyyy')
/**
 * formats the assignments data readable for the table
 * @param list
 */
const mapAssignments = (list: any[]) =>
  list.length
    ? list.map((assignment: IMappedAssignments) => {
        const {
          worker,
          training,
          lastUpdatedDateTime = '',
          lastUpdatedBy = '',
          startedTraining: timeStarted = '',
        } = assignment
        const {
          operationsManager = '',
          deptGroupName: dept = '',
          empId = '',
          full_name: fullName = '',
          mascot = '',
          supervisorName = '',
          siteName: site = '',
          businessUnit = '',
          timeZone = '',
          deptGroupId = '',
        } = worker
        const {
          taskSids = [],
          trainingLength = '',
          startedTraining = '',
          taskEndedDateTime: taskEndTime = '',
          percentComplete = '', // timeSpent / duration
          completionPercent = '', // completed/(accepted - ejected)
          priority = '',
          tags = [],
          timeInTraining: timeSpent = '',
          ejected: timesEjected = '0',
          trainingName = '',
          taskEndedDateTime = '',
          percentTimeSpent = '',
        } = training
        return {
          ...assignment,
          attempts: taskSids.length || 0,
          businessUnit,
          deptGroupId,
          completionPercent,
          dept,
          duration: `${trainingLength}min`,
          empId,
          fullName,
          lastModified:
            lastUpdatedDateTime && lastUpdatedBy
              ? `${formatDateOnly(lastUpdatedDateTime)} ${lastUpdatedBy}`
              : '',
          mascot,
          operationsManager,
          percentComplete,
          percentTimeSpent,
          priority,
          site,
          startedTraining,
          status: training?.timeOfCompletion ? 'Completed' : 'Incomplete',
          supervisorName,
          tags: JSON.stringify(tags) || '',
          timeSpent,
          timesDelivered: (taskSids && taskSids.length) || '0',
          timesEjected,
          timeStarted,
          trainingName,
          taskEndTime,
          taskEndedDateTime,
          timeZone,
        }
      })
    : []

export {
  updateAssignments,
  getAssignments,
  batchGetAssignmentsByAssignmentId,
  getAssignmentsByWorker,
  getAssignmentsForTraining,
  getTwilioToken,
  getAssignmentsByAssignmentId,
  mapAssignments,
  postAssignmentsData,
  getAssignmentsByBundleId,
  getUpdatedAssignmentsByBundleId,
}
